html, body {
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
  background-image: url(./img/background.png);
  background-size: contain;
}

.nav-link {
  font-family: "Poppins", sans-serif !important;
  font-size: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.dropdown-item {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  position: relative;
  top: 2px;
}
.form-control {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}
.dropdown-menu[data-bs-popper] {
  left: -30px !important;
}
.nav-tabs {
  border-bottom: 0px !important;
}
.nav-tabs .nav-link {
  border: 0 !important;
  background-color: transparent !important;
  padding: 10px 30px !important;
  margin: 0 ;
  border: 1px solid #4878cf !important;
  border-radius: 20px !important;
  color: #4878cf !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
ul.nav.nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #4878cf !important;
  border: 1px solid #fff !important;
  border-radius: 20px;
}



.hide {
  display: none !important;
}
.custom-navbar {
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.nav-btn {
  padding: 10px 15px;
  color: #4878cf;
  border-radius: 10px;
  border: 1px solid #4878cf;
}
.logo {
  width: 130px;
}
.iw-100 {
  width: 100%;
}
.main-left {
  background-image: url( ./img/shape02.png );
  background-size: contain;
  padding: 0 !important;
  position: relative;
}
.main-right {
  padding-left: 25px !important;
}
.separator {
  height: 70px;
}
.main-container {
  position: relative;
}
.line-info {
  position: absolute;
  left: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  max-width: 200px;
  border-radius: 20px;
}
.line-title {
  font-family: "Hahmlet", serif;
  font-size: 25px;
  font-weight: bold;
  text-align: left;
}
.line-subtitle {
  font-family: "Poppins", serif;
  font-size: 15px;
  color: #6d6d6d;
  text-align: left;
}
.line-title-small {
  font-size: 18px;
}
.line-subtitle-small {
  font-size: 14px;
}
.line-card {
  position: absolute;
  right: -10px;
  bottom: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  max-width: 200px;
  z-index: 90;
  border-radius: 20px;
}
.home-title {
  font-family: "Hahmlet", serif;
  font-size: 55px;
  line-height: 60px;
  text-align: left;
  padding-bottom: 25px;
}
.home-subtitle {
  font-family: "Poppins", serif;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #8c8c8c;
}
.home-style {
  padding: 20px;
}
.home-title-squart {
  position: absolute;
  left: -5px;
  top: 5px;
  width: 18px;
  height: 18px;
  background-color: #4878cf;
}
.home-item-title {
  position: relative;
  font-family: "Hahmlet", serif;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  padding-bottom: 10px;
  z-index: 10;
}
.home-item-subtitle {
  font-family: "Poppins", serif;
  font-size: 12px;
  text-align: left;
  color: #8c8c8c;
}

.txt-center {
  text-align: center;
}
.section-a {
  position: relative;
  background-color: transparent;
  padding: 50px; 
}
.section-title {
  font-size: 35px;
  font-weight: 800;
  line-height: 60px;
  margin-bottom: 18px;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Hahmlet', monospace;
  color: #000;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.section-desc {
  font-family: 'Poppins', monospace;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  padding: 0 15%;
}
.col-promos {
  margin: 10px 0 !important;
}
.card-container {
  background-color: #fff;
  box-shadow: rgba(11, 43, 158, 0.15) 0px 6px 20px -6px;
  border-radius: 20px;
  padding: 50px;
  text-align: left;
  height: 100%;
  position:relative;
}
.card-container-active {
  border: 5px solid green;
}
.card-container-list {
  /*background-color: #fff;
  border-radius: 40px;
  box-shadow: rgba(11, 43, 158, 0.15) 0px 6px 20px -6px;
  margin: 10px;
  text-align: left;*/
  display: flex;
}
.card-container-list:first-child {
  margin-left: 0;
}
.card-container-list:last-child {
  margin-right: 0;
}
.card-recomended {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Poppins', monospace;
  color: #000;
  text-align: center;
}
.card-title {
  font-size: 22px;
  font-weight: 700;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Hahmlet', monospace;
  color: #000;
  text-align: center;
}
.card-subtitle {
  font-size: 15px;
  font-weight: 200;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Poppins', monospace;
  color: #000;
  text-align: center;
}
.card-price {
  font-size: 55px;
  font-weight: 700;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Hahmlet', monospace;
  color: #000;
}
.card-period {
  font-size: 15px;
  font-weight: 300;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Poppins', monospace;
  color: #000;
}
.card-elements {
  font-size: 13px;
  font-weight: 300;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Poppins', monospace;
  color: #000;
}
.card-done {
  width: 15px;
  height: auto;
}
.card-image {
  width: auto;
  height: auto;
}
.price-card {
  padding: 10px;
}
.section-price {
  background-color: #f3eefd !important;
}
.profile {
  width: 35px;
  height:  35px;
}
.menu-item-icon {
  position: relative;
  top: -2px;
  width: 15px;
  height:  15px;
  font-size: 15px;
}
.welcome-profile {
  padding: 4px 16px;
  text-align: center;
  font-family: 'Poppins', monospace;
  font-weight: 600;
  font-size: 18px;
  color: #007ad7;
}
.login-container {
    width: 380px;
    margin: 0 auto;
}
.login-title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
}
.form-style {
  font-family: "Poppins", sans-serif;
  border: 1px solid #eae9e9;
  border-radius: 15px;
  padding: 20px 25px !important;
  background-color: #f4f7fc;
}
.form-label {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}
.submit-btn {
  font-family: "Poppins", sans-serif;
  font-size: 14px !important;
  background-color: #4878cf !important;
  color: #fff;
  padding: 10px 25px !important;
  border: 0 !important;
}
.submit-btn-green {
  font-family: "Poppins", sans-serif;
  font-size: 14px !important;
  background-color: green !important;
  color: #fff;
  width: 100%;
  border: 0 !important;
}
.form-extras-style {
  font-family: "Poppins", sans-serif;
  border: 1px solid #eae9e9;
  border-radius: 15px;
  padding: 20px 25px !important;
}
.forgoten-pass {
  font-family: "Poppins", sans-serif;
  font-size: 13px !important;
  font-weight: 300;
  padding-bottom: 10px;
}
.hight-text {
  color: #4878cf !important;
  cursor: pointer;
  font-weight: 500;
}
.text-muted {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  line-height: 10px !important;
  font-weight: 300 !important;
}
.verified-email {
  text-decoration: underline;
  cursor: pointer;
}

.list-preview-main {
  border-radius: 20px;
  width: 100%;
}
.list-video-main {
  cursor: pointer;
}
.list-video {
  margin-top: 5px !important;
  margin-bottom: 5px;
  cursor: pointer;
}
.list-preview {
  border-radius: 10px;
  width: 100%;
  height: 400px;
  margin-bottom: 15px;
}
.list-title {
  font-size: 15px;
  font-weight: 700;
}
.list-date, .list-duration {
  font-size: 12px;
  font-weight: 400;
}


.modal-dialog {
  max-width: fit-content !important;
}
.modal-content {
  background-color: transparent !important;
  border: 0 !important;
}
.modal-header {
  border: 0 !important;
  padding: 0 !important
}
.btn-close {
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%) !important;
  --bs-btn-close-bg: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQElEQVR4nO2ZQWrDMBBFfZQucpIESvZRsrCbs3fRM4RAEnglpKYO2CCPNaOR0d8ZjP5/GslI46apqqqqWpWADRAy+odnhqWDfADfwAM4J0sX738C7sCPGGYA0csUhn+IXjIY4PgXfqjnc6eS/N27m/A+ppoV9cqg5WkJg7aXBYzZhKFoZL6EUTDMsQ+TG2eDSBkgO0SKIG4glgRyByEJ5hZiTkD3EDFBi4GIOOxlOXwu0sTsl1GJGTDlQKwGhDUsLaAtfrOzhs8vr/v9WNCvwTu+YYiAcA/DDAi3MAgg3MHwamOKIDzdDsNSCA/39TBh3JXUQQmpIXL0tIIWhGWXMQA3TQiLvu9hAqJNll4bhvHldBe39tPcNFvJYJ/A1aoSEZW5ADvpYD2MKcQIjBxiMNg+88/QFtjm8q+qqqpqVPQL08n6GKXmFcYAAAAASUVORK5CYII=") !important;
  border-radius: 50% !important;
  border: 1px solid #fff !important;
}
.modal-message {
  max-width: 500px;
  background-color: #fff;
  padding: 30px;
  text-align: center;
  border-radius: 20px;
}
.modal-title {
  font-size: 30px;
  font-weight: 800;
  color: orange;
}
.chat-text {
  font-size: 12px;
  color: #6d6d6d;
}
.footer {
  background-color: #4878cf;
  padding: 35px;
}
.footer-privacy {
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}
.us-container {
  padding: 30px;
}
.c-img {
  width: 100%;
}
.c-text {
  min-height: 200px;
}
.back-btn {
  padding-left: 10px;
}
.video-size {
  width: 640px;
  height: 480px;
  position: relative
}
.rrss_style {
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}
.player-container {
  width: 640px; 
  height: 360px;
  position: relative
}
.player-mask-top {
  width: 100%;
  height: 310px;
  position: absolute;
  opacity: 0;
  right: 0px;
  top: 0px;
}
.player-mask-bottom {
  width: 80%;
  height: 39px;
  position: absolute;
  opacity: 0;
  right: 0px;
  bottom: 0px;
}

@media (max-width: 460px) {
  .dropdown-menu[data-bs-popper] {
    left: 28% !important;
  }
  .nav-link {
    padding-top: 10px !important;
  }
  .line-card {
    right: 0;
    bottom: 0;
  }
  .home-title {
    font-size: 40px;
    line-height: 45px;
    text-align: center;
  }
  .home-subtitle{
    text-align: center;
  }
  .section-title {
    font-size: 25px;
    line-height: 35px;
    text-align: center;
  }
  .txt-left {
    text-align: center;
  }
  .video-size {
    width: 100% !important;
    height: 280px !important;
    position: relative;
  }
  .modal-body-style {
    border-radius: 15px;
    background-color: #fff;
  }
  .player-container {
    width: 100%; 
    height: 200px;
    position: relative
  }
  .player-mask-top {
    width: 100%;
    height: 80px;
    position: absolute;
    opacity: 0;
    right: 0px;
    top: 0px;
  }
  .player-mask-bottom {
    display: none;
  }
  .modal-dialog {
    max-width: 100% !important;
  }
}
